import { Controller } from '@hotwired/stimulus';
import 'jquery-zoom';

declare global {
  interface JQuery {
    zoom(options: { url?: string; magnify?: number }): JQuery;
  }
}

// Connects to data-controller="record-image"
export default class extends Controller {
  static targets = ['image', 'container', 'notice', 'noticeHeader'];

  declare imageTarget: HTMLImageElement;
  declare containerTarget: HTMLDivElement;
  declare noticeTarget: HTMLDivElement;
  declare noticeHeaderTarget: HTMLDivElement;

  connect(): void {
    const url = this.containerTarget.dataset.full;
    this.#enableZoom(url);
  }

  show(event: Event): void {
    const element = event.currentTarget as HTMLDivElement;
    this.imageTarget.src = element.dataset.full ?? '';
    this.imageTarget.srcset = '';
    this.noticeTarget.innerText = element.dataset.notice ?? '';
    this.noticeHeaderTarget.classList.toggle('hidden', !element.dataset.notice);
    this.#enableZoom(element.dataset.full);
  }

  #enableZoom(url?: string): void {
    $(this.containerTarget).zoom({ url });
  }
}
