import { Controller } from '@hotwired/stimulus';

export default class RecordsTableController extends Controller {
  static targets = [];

  toggleRow(event: MouseEvent): void {
    if (!event.currentTarget) return;

    const button = event.currentTarget as HTMLButtonElement;
    const row = button.closest('tr');
    if (!row) throw Error('Could not find parent tr');

    const newState = row.classList.toggle('dev-is-closed');
    row.classList.toggle('dev-is-open', !newState);
  }
}
